@font-face {
    font-family: 'dm_sansbold';
    src: url('./fonts/dmsans-bold.eot');
    src: url('./fonts/dmsans-bold.eot?#iefix') format('embedded-opentype'),
        url('./fonts/dmsans-bold.woff2') format('woff2'),
        url('./fonts/dmsans-bold.woff') format('woff'),
        url('./fonts/dmsans-bold.svg#dm_sansbold') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'dm_sansitalic';
    src: url('./fonts/dmsans-italic.eot');
    src: url('./fonts/dmsans-italic.eot?#iefix') format('embedded-opentype'),
        url('./fonts/dmsans-italic.woff2') format('woff2'),
        url('./fonts/dmsans-italic.woff') format('woff'),
        url('./fonts/dmsans-italic.svg#dm_sansitalic') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'dm_sansmedium';
    src: url('./fonts/dmsans-medium.eot');
    src: url('./fonts/dmsans-medium.eot?#iefix') format('embedded-opentype'),
        url('./fonts/dmsans-medium.woff2') format('woff2'),
        url('./fonts/dmsans-medium.woff') format('woff'),
        url('./fonts/dmsans-medium.svg#dm_sansmedium') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'dm_sansmedium_italic';
    src: url('./fonts/dmsans-mediumitalic.eot');
    src: url('./fonts/dmsans-mediumitalic.eot?#iefix') format('embedded-opentype'),
        url('./fonts/dmsans-mediumitalic.woff2') format('woff2'),
        url('./fonts/dmsans-mediumitalic.woff') format('woff'),
        url('./fonts/dmsans-mediumitalic.svg#dm_sansmedium_italic') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'dm_sansregular';
    src: url('./fonts/dmsans-regular.eot');
    src: url('./fonts/dmsans-regular.eot?#iefix') format('embedded-opentype'),
        url('./fonts/dmsans-regular.woff2') format('woff2'),
        url('./fonts/dmsans-regular.woff') format('woff'),
        url('./fonts/dmsans-regular.svg#dm_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;

}
/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
    font-family: 'Acumin Pro';
    font-style: normal;
    font-weight: normal;
    src: local('Acumin Pro'), url('./fonts/Acumin-RPro.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Acumin Pro Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Acumin Pro Italic'), url('./fonts/Acumin-ItPro.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Acumin Pro Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Acumin Pro Bold'), url('./fonts/Acumin-BdPro.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Acumin Pro Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Acumin Pro Bold Italic'), url('./fonts/Acumin-BdItPro.woff') format('woff');
    }