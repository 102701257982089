.grid-section {
    .grid-panel {
        display: flex;
        flex-flow: wrap;
        width: 100%;
        padding-top: $top-spce-smll;

        @media (min-width: $width-991) {
            padding-top: $top-spce;

        }

        .grid {
            width: calc(100%);
            background-color: black;
            position: relative;
            min-height: 200px;
            z-index: 1;
            margin-bottom: 40px;

            @media (min-width: $width-991) {
                width: calc(100% / 3 - 30px);
                margin-right: 45px;
                margin-bottom: 0;
            }

            .grid-content {
                width: 100%;
                background-color: black;
                height: 100%;
                position: relative;
                z-index: 1;
                text-align: center;
                padding: 30px;

                .grid-icon {
                    width: 100%;

                    img {
                        max-width: 130px;
                        max-height: 80px;

                        @media (max-width: $width-991) {
                            max-width: 100px;
                            max-height: 60px;
                        }
                    }
                }

                h2 {
                    width: 100%;
                    text-transform: uppercase;
                    font-size: 17px;
                    color: $yellow;
                    padding: 20px 0;

                    @media (min-width: $width-576) {
                        font-size: 20px;

                    }
                }

                p {
                    width: 100%;
                }
            }

            &:before {
                content: "";
                background-color: $yellow;
                width: 40px;
                height: 40px;
                position: absolute;
                top: -5px;
                left: -5px;
                z-index: 0;
                opacity: .8;
            }

            &:after {
                content: "";
                background-color: $yellow;
                width: 40px;
                height: 40px;
                position: absolute;
                right: -5px;
                bottom: -5px;
                z-index: 0;
                opacity: .8;
            }

            &:last-child {
                margin-right: 0;
            }
        }

    }

}

.beforehand-section {
    .beforehand-content {
        display: flex;
        flex-flow: wrap;
        width: 100%;
        padding-top: $top-spce-smll;

        @media (min-width: $width-991) {
            padding-top: $top-spce;

        }

        .imagery-with-content {
            width: 100%;
            display: flex;
            margin: 0 auto;
            flex-flow: wrap;
            align-items: center;
            padding-top: 50px;

            @media (min-width: $width-991) {
                width: 100%;
                padding-top: 80px;
            }

            @media (min-width: $width-1200) {
                width: 90%;
            }

            @media (min-width: $width-1400) {
                width: 75%;
            }

            .beforehand-image {
                width: 300px;
                height: 300px;
                border-radius: 50%;
                overflow: hidden;
                margin: 0 auto;

                @media (min-width: $width-991) {
                    width: 400px;
                    height: 400px;
                    margin: 0;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;

                }
            }

            .beforehand-texts {
                width: calc(100%);
                padding-top: 30px;

                @media (min-width: $width-991) {
                    width: calc(100% - 400px);
                    padding-left: 50px;
                    padding-right: 20px;
                    padding-top: 0;
                }

                p {
                    font-size: 15px;
                    color: $yellow;
                    line-height: 24px;

                    @media (min-width: $width-991) {
                        font-size: 18px;
                        line-height: 30px;
                    }
                }
            }
        }
    }
}


.get-going-section {
    .get-going-content {
        display: flex;
        flex-flow: wrap;
        width: 100%;
        padding-top: $top-spce-smll;

        @media (min-width: $width-991) {
            padding-top: $top-spce;

        }

        .going-list-2 {
            display: flex;
            flex-flow: wrap;
            width: 100%;
            padding-top: 40px;

            @media (min-width: $width-991) {
                padding-top: 60px;

            }

            li {
                width: 100%;
                margin-bottom: 35px;

                @media (min-width: $width-991) {

                    width: calc(100% / 3);
                    margin-bottom: 0;
                    padding-right: 30px;
                }

                .image {
                    max-width: 65px;
                    max-height: 65px;
                    margin-bottom: 10px;

                    @media (min-width: $width-991) {
                        max-width: 75px;
                        max-height: 75px;
                    }
                }

                h3 {
                    font-family: $medium;
                    font-size: 18px;
                    color: white;
                    padding: 15px 0 7px 0;

                    @media (min-width: $width-991) {
                        font-size: 20px;

                    }
                }

            }
        }

    }
}


.testimonial-banner {
    width: 100%;
    padding-top: $top-spce-smll;

    @media (min-width: $width-991) {
        padding-top: $top-spce;

    }
    .name-t{
        width: 100%;
        text-transform: uppercase;
        color:$yellow;
        padding-top: 15px;
        p{
            display: block;
            width: 100%;
            color:white;
            font-size:13px;
            text-transform: none;
        }
    }

    .carousel-caption {
        position: static;
    }

    .carousel-item {
        min-height: 250px;
        overflow: hidden;

    }

    .testimonial-content {
        max-width: 800px;
        margin: 0 auto;
        position: relative;
        padding-bottom: 20px;
    }

    .carousel-indicators {
        bottom: -30px;
        z-index: 9;

        button {
            width: 10px;
            height: 10px;
            border-radius: 50%;
        }

        button {
            &.active {
                background-color: $yellow;
            }
        }
    }

    .testi-1 {
        position: absolute;
        top: 110px;
        left: -20px;
        max-width: 20px;

        @media (min-width: $width-991) {
            top: 110px;
            left: -50px;
            max-width: 40px;
        }

        @media (max-width: $width-576) {
            left: -2px;
        }
    }

    .testi-2 {
        position: absolute;
        bottom: 6px;
        right: -23px;
        max-width: 20px;

        @media (min-width: $width-991) {
            bottom: 16px;
            right: -43px;
            max-width: 40px;
        }

        @media (max-width: $width-576) {
            right: -2px;
        }
    }

    .carousel-image {
        margin: 20px auto;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

}

.testimonial-banner .carousel-control-next,
.testimonial-banner .carousel-control-prev {
    display: none;
}

.handled-rich-section {
    .handled-rich-content {
        width: 100%;
        padding-top: $top-spce-smll;

        @media (min-width: $width-991) {
            padding-top: $top-spce;

        }

        .rich-out-2 {
            display: flex;
            flex-flow: wrap;
            width: 100%;
            padding-top: 40px;

            @media (min-width: $width-991) {
                padding-top: 60px;

            }

            li {
                width: 100%;
                margin-bottom: 35px;
                border: solid 1px $yellow;

                @media (min-width: $width-991) {

                    width: calc(100% / 3 - 30px);
                    margin-bottom: 0;
                    margin-right: 45px;

                }

                &:last-child {
                    margin-right: 0;
                }

                .image {
                    max-width: 100%;
                    height: 150px;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                    }

                    &.mt-b {
                        margin-bottom: 20px;
                    }

                }

                h3 {
                    font-family: $medium;
                    font-size: 16px;
                    color: $yellow;
                    padding: 20px;

                    @media (min-width: $width-991) {
                        font-size: 18px;
                    }
                }

                p {
                    position: relative;
                    padding-left: 50px;
                    padding-right: 20px;
                    padding-bottom: 15px;

                    &:before {
                        content: "";
                        position: absolute;
                        left: 20px;
                        background-image: url('../../../public/img/aero.png');
                        background-size: 100%;
                        width: 18px;
                        height: 18px;
                        top: 2px;
                    }
                }

            }
        }
    }
}


.takeoffPoint-2 {
    .takeoffPoint-2-content {
        display: flex;
        flex-flow: wrap;
        width: 100%;
        flex-direction: row-reverse;
        padding-top: $top-spce-smll;

        @media (min-width: $width-991) {
            padding-top: $top-spce;

        }

        .takeoffPoint-for-2 {
            width: 100%;

            @media (min-width: $width-991) {
                width: 50%;
                padding-left: 70px;
            }

            .red-more-button {
                margin-top: 10px;
                margin-left: 10px;

                @media (min-width: $width-991) {
                    margin-top: 30px;

                }
            }

            h3 {
                font-size: 22px;
                text-transform: uppercase;
                color: $yellow;

                @media (min-width: $width-991) {
                    font-size: 30px;

                }
            }

            ul {
                li {
                    position: relative;
                    padding-left: 30px;
                    margin-top: 21px;
                    display: block;
                    width: 100%;
                    font-size: 15px;

                    @media (min-width: $width-991) {
                        margin-top: 25px;
                        font-size: 17px;

                    }

                    @media (min-width: $width-1200) {
                        margin-top: 41px;
                    }

                    &:before {
                        content: "";
                        position: absolute;
                        left: 0px;
                        background-image: url('../../../public/img/aero.png');
                        background-size: 100%;
                        width: 18px;
                        height: 18px;
                        top: 2px;
                    }
                }
            }
        }

        .TakeoffPoint-img {
            width: 100%;
            display: none;

            @media (min-width: $width-991) {
                width: 50%;
                display: block;
            }
        }

    }
}


.take-drive-2 {
    width: 100%;
    background-color: $yellow;
    margin-top: 60px;
    padding: 20px 0;
    position: relative;
    z-index: 1;

    .take-test-2 {
        display: flex;
        flex-flow: wrap;
        align-items: center;

        li {
            width: calc(100%);
            padding-left: 0px;

            @media (min-width: 767px) {
                width: calc(100% - 200px);
                padding-left: 60px;
            }

            h3 {
                text-transform: uppercase;
                color: black;
                font-size: 20px;

                @media (min-width: 767px) {
                    font-size: 22px;
                }
            }

            &:first-child {
                @media (min-width: 767px) {
                    width: 200px;
                    padding-left: 0;
                }
            }

            p {
                color: black;
                font-size: 16px;
            }

            .red-more-button {
                margin-top: 3px;
                width: 100%;

                .mr-l {
                    margin-top: 0;
                }
            }

        }
    }

}