.estimate-page {
    width: 100%;
    padding-top: 65px;
    font-family: 'Acumin Pro';

    .signup-btn {
        a {
            display: block;
            color: #ffc115;
            font-size: 16px;
            height: 40px;
            padding: 0;
            margin: 0;
            line-height: 40px;
            width: 175px;
            display: flex;
            align-items: center;
            justify-content: center;

            @media (max-width: $width-1200) {
                font-size: 14px;
                width: 155px;
                height: 37px;
                line-height: 37px;
            }
        }

        border-radius: 25px;
        background-color: #040000;
        border:solid 2px rgb(253, 222, 132, .58);
        color:#ffc115;
        font-size:16px;
        font-family: 'Acumin Pro Bold';
        text-transform: uppercase;
        width: auto;
        height: auto;
        padding: 0;
        margin: 30px 0 0 0;

        &:hover {
            background-color: #071927;
            border-color: rgb(253, 222, 132, .58);
        }


    }

    .estimate-banner {
        background-color: #071927;
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        flex-flow: wrap;
        position: relative;

        @media (min-width: $width-576) {
            height: 400px;

        }

        @media (min-width: $width-991) {
            height: 450px;
        }

        @media (min-width: $width-1200) {
            height: 550px;
        }

        @media (min-width: $width-1400) {
            height: 610px;

        }


        .estimate-bg {
            position: absolute;
            right: 0;
            bottom: 0;

            img {
                @media (max-width: $width-1200) {
                    width: 350px;
                }

                @media (max-width: $width-576) {
                    width: 300px;
                }
            }
        }

        .round {
            background: #071927;
            width: 60px;
            height: 60px;
            border-radius: 50%;
            position: absolute;
            left: 8px;
            bottom: 10px;
            border: solid 5px #ffd866;
            opacity: 0.2;

            @media (max-width: $width-576) {
                display: none;
            }
        }

        .round-small {
            background: #071927;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            position: absolute;
            left: 30px;
            bottom: 51px;
            border: solid 4px #ffd866;
            opacity: 0.2;

            @media (max-width: $width-576) {
                display: none;
            }
        }

        .container {
            display: flex;
            align-items: center;
            flex-flow: wrap;

            .row {
                width: 100%;
            }
        }

        .banner-heading {
            width: 50%;
            display: flex;
            flex-flow: wrap;
            align-items: center;
            font-family: 'Acumin Pro';

            @media (max-width: $width-576) {
                width: 100%;
                padding-top: 30px;
                text-align: center;
            }

            @media (min-width: $width-1200) {
                padding-left: 40px;

            }

            .subheading {
                font-size: 17px;
                color: #ffd866;

                @media (min-width: $width-1200) {
                    font-size: 19px;

                }

                @media (min-width: $width-1400) {
                    font-size: 24px;

                }

            }

            h2 {
                font-size: 28px;
                margin: 15px 0;
                padding: 0;
                font-family: 'Acumin Pro Bold';

                @media (min-width: $width-576) {
                    font-size: 28px;
                }

                @media (min-width: $width-991) {
                    font-size: 32px;
                }

                @media (min-width: $width-1200) {
                    font-size: 45px;
                }

                @media (min-width: $width-1400) {
                    font-size: 54px;

                }

            }

            p {
                font-size: 17px;
                line-height: normal;

                @media (min-width: $width-1200) {
                    font-size: 19px;

                }

                @media (min-width: $width-1400) {
                    font-size: 24px;

                }
            }
        }

        .banner-img {
            width: 50%;
            text-align: right;

            @media (max-width: $width-576) {
                width: 100%;
                padding: 30px 0;
                text-align: center;
            }

            img {
                max-width: 90%;

                @media (min-width: $width-576) {
                    max-height: 350px;
                    max-width: 100%;

                }

                @media (min-width: $width-1200) {
                    max-height: 450px;
                }

                @media (min-width: $width-1400) {
                    max-width: 100%;
                }
            }
        }
    }

    .estimate-services {
        width: 100%;
        background-color: #fce193;
        display: flex;
        flex-flow: wrap;
        padding: 30px 0;

        .container {
            @media (min-width: $width-1200) {
                padding: 0 100px;
            }
        }

        .services-grid {
            width: calc(100% / 3);
            display: flex;
            justify-content: center;
            flex-flow: wrap;
            padding: 10px;

            @media (max-width: $width-576) {
                width: 100%;
                padding: 15px;
            }

            .service-icon {
                height: 90px;
                width: 90px;
                margin: 0 auto 20px auto;
                text-align: center;
                border-radius: 50%;
                background-color: #284c68;
                padding: 15px;
                display: flex;
                align-items: center;
                justify-content: center;


                @media (max-width: $width-991) {
                    height: 70px;
                    width: 70px;
                }

                img {
                    max-height: 100%;
                    max-width: 100%;



                }
            }

            p {
                font-family: 'Acumin Pro Bold';
                font-size: 16px;
                color: black;
                width: 100%;
                text-align: center;
                line-height: normal;

                @media (min-width: $width-1200) {
                    font-size: 19px;
                }

                br {
                    @media (max-width: $width-991) {
                        display: none;
                    }
                }

            }

        }
    }

    .services-content-with-trail {
        width: 100%;
        display: flex;
        flex-flow: wrap;
        position: relative;

        .container {
            @media (min-width: $width-1200) {
                padding: 0 100px;
            }
        }

        @media (max-width: $width-576) {
            background-color: white;
        }

        .outer-box {
            width: 50%;
            background-color: #f1f2f3;
            position: absolute;
            height: 100%;
            left: auto;
            right: 0;

            @media (max-width: $width-576) {
                display: none;
            }

            &:first-child {
                background-color: white;
                left: 0;
                top: 0;
            }

        }

        .left-service-content {
            width: 50%;
            padding: 70px 10px;

            @media (max-width: $width-576) {
                width: 100%;
                padding: 45px 10px;

            }

            ul {
                margin: 0;
                width: 100%;
                padding: 0 0 0 20px;

                li {
                    width: 100%;
                    font-size: 16px;
                    color: black;
                    font-family: 'Acumin Pro';
                    list-style: disc;
                    list-style-type: disc;
                    padding-bottom: 25px;

                    @media (min-width: $width-991) {
                        font-size: 18px;

                    }

                    @media (min-width: $width-1200) {
                        font-size: 21px;

                    }

                    &:last-child {
                        padding-bottom: 0;
                    }
                }
            }
        }

        .service-trial-content {
            width: 50%;
            padding: 70px 0;
            display: flex;
            flex-flow: wrap;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            color: black;

            @media (max-width: $width-576) {
                width: 100%;
                background: #f3f3f3;
                padding: 50px 0;

            }

            p {
                font-size: 16px;
            }

            .signup-btn {
                margin-top: 20px;
                border: 0;
                width: 165px;
                font-size: 14px;
                padding-top: 2px;

                @media (max-width: $width-576) {
                    width: 165px;

                }

                a {
                    font-size: 14px;
                    width: 165px;


                }
            }

            h4 {
                font-size: 22px;
                padding-bottom: 5px;
                font-family: 'Acumin Pro Bold';
                color: black;


                @media (min-width: $width-1200) {
                    font-size: 26px;

                }
            }

        }
    }

    .service-summary {
        width: 100%;
        height: 253px;
        background: #071927 url('../../../public/img/estimate-bg-2.png');
        background-repeat: no-repeat;
        background-position: right;

        .container {
            height: 100%;
        }

        .row {
            align-items: center;
            justify-content: center;
            height: 100%;
        }

        .service-summary-content {
            width: 100%;
            text-align: center;

            .sub-heading {
                color: #ffc115;
                font-family: 'Acumin Pro';
                font-size: 17px;
                padding-bottom: 5px;

                @media (min-width: $width-576) {
                    font-size: 18px;
                }

                @media (min-width: $width-991) {
                    font-size: 22px;
                }

            }

            .main-heading {
                font-family: 'Acumin Pro';
                font-size: 17px;
                padding: 6px 0;
                text-transform: uppercase;

                @media (min-width: $width-576) {
                    font-size: 21px;

                }

                @media (min-width: $width-991) {
                    font-size: 22px;

                }
            }

            .heading {
                font-family: 'Acumin Pro Bold';
                font-size: 20px;

                @media (min-width: $width-576) {
                    font-size: 24px;

                }

                @media (min-width: $width-991) {
                    font-size: 30px;

                }
            }
        }
    }

    .review-section {
        width: 100%;
        background-color: white;
        padding: 40px 0;
        color: black;

        @media (min-width: $width-991) {
            padding: 60px 0;

        }

        .review-wrap {
            max-width: 1038px;
            margin: 0 auto;
        }

        h2 {
            font-family: 'Acumin Pro Bold';
            font-size: 21px;
            color: black;

            @media (min-width: $width-991) {
                font-size: 28px;

            }
        }

        .client-review {
            width: 100%;
            padding-top: 30px;
            max-width: 750px;

            h3 {
                font-size: 13px;
                font-family: 'Acumin Pro Bold';
                color: #353636;
                padding-bottom: 5px;

                @media (min-width: $width-991) {
                    font-size: 14px;

                }

            }

            p {
                line-height: normal;
                padding-top: 5px;
            }

            .rating {
                width: 100%;

                .star {
                    position: relative;
                    display: inline-block;
                    width: 0;
                    height: 0;
                    margin-left: .9em;
                    margin-right: .9em;
                    margin-bottom: 1.2em;
                    border-right: .3em solid transparent;
                    border-bottom: .6em solid rgb(190, 190, 189);
                    border-left: .3em solid transparent;
                    font-size: 10px;

                    &:before,
                    &:after {
                        content: '';

                        display: block;
                        width: 0;
                        height: 0;
                        position: absolute;
                        top: .5em;
                        left: -1em;
                        border-right: 1em solid transparent;
                        border-bottom: .6em solid rgb(190, 190, 189);
                        border-left: 1em solid transparent;
                        transform: rotate(-35deg);
                    }

                    &:after {
                        transform: rotate(35deg);
                    }

                    &.active {
                        border-bottom: .6em solid #ffc700;

                        &:before,
                        &:after {
                            border-bottom: .6em solid #ffc700;

                        }

                    }

                }
            }
        }
    }

    .team-time-cost-summary {
        width: 100%;
        background: white;
        color: black;
        padding: 40px 0;
        display: flex;
        flex-flow: wrap;

        .container {
            @media (min-width: $width-1200) {
                padding: 0 100px;
            }
        }

        .team-summary {
            width: 60%;
            align-items: center;
            justify-content: center;
            margin: auto;
            font-family: 'Acumin Pro';

            @media (max-width: $width-576) {
                width: 100%;
            }

            .top-text {
                padding-bottom: 40px;

                h4 {
                    font-size: 20px;
                    padding-bottom: 6px;
                    font-family: 'Acumin Pro';

                    @media (max-width: $width-991) {

                        font-size: 16px;
                    }

                    @media (min-width: $width-1200) {
                        font-size: 23px;

                    }
                }

                h1 {
                    font-size: 28px;
                    padding-bottom: 6px;
                    color: #040000;
                    font-family: 'Acumin Pro';

                    @media (max-width: $width-991) {

                        font-size: 22px;
                    }

                    @media (min-width: $width-1200) {
                        font-size: 32px;

                    }
                }

                p {
                    font-size: 16px;
                    font-family: 'Acumin Pro';

                    @media (max-width: $width-991) {

                        font-size: 15px;
                    }

                    @media (min-width: $width-1200) {
                        font-size: 20px;

                    }

                }
            }

            .bottom-text {
                h3 {
                    font-size: 22px;
                    padding-bottom: 10px;
                    font-family: 'Acumin Pro';

                    @media (max-width: $width-991) {
                        font-size: 18px;
                    }

                    @media (min-width: $width-1200) {
                        font-size: 26px;

                    }
                }

                p {
                    line-height: 18px;
                    font-size: 14px;
                    font-family: 'Acumin Pro';

                    @media (max-width: $width-991) {

                        font-size: 14px;
                    }

                    br {
                        @media (max-width: $width-991) {
                            display: none;
                        }

                    }
                }
            }
        }

        .team-image {
            width: 40%;
            text-align: right;

            @media (max-width: $width-576) {
                width: 100%;
                padding-top: 40px;
                text-align: center;
            }

            img {
                @media (max-width: $width-576) {
                    max-width: 85%;
                }
            }
        }
    }

    .subscribe-container {
        background-color: #071927;
        width: 100%;
        padding: 60px 0;

        h2 {
            font-size: 30px;
            font-family: 'Acumin Pro Bold';
            color: white;
            width: 100%;
            text-align: center;

            @media (max-width: $width-991) {
                font-size: 24px;
            }
        }

        .sign-up-container {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            padding-top: 50px;
            max-width: 1038px;
            margin: 0 auto;

            @media (max-width: $width-991) {
                max-width: 534px;

            }

            @media (max-width: $width-576) {
                max-width: 82%;

            }

            .price-grid {
                width: calc(100% / 4 - 20px);
                margin-right: 20px;
                background-color: white;
                border-radius: 4px;
                padding: 20px;
                text-align: center;
                color: black;
                display: flex;
                justify-content: center;
                flex-direction: column;

                @media (max-width: $width-991) {
                    width: calc(100% / 2 - 20px);
                    margin-bottom: 30px;

                }


                @media (max-width: $width-576) {
                    width: 100%;
                    margin: 0 0 30px 0;
                }

                .plan-heading {
                    width: 80px;
                    height: 80px;
                    background-color: #071927;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #ffd200;
                    text-transform: uppercase;
                    font-size: 16px;
                    margin: 0 auto;
                    font-family: 'Acumin Pro Bold';


                    &.white-bg {
                        background-color: rgb(239, 237, 237);
                        color: white;

                        @media (max-width: $width-576) {
                            // display: none;
                        }
                    }
                }

                .price {
                    margin-top: 30px;
                    min-height: 20px;

                    @media (max-width: $width-576) {
                        margin-top: 20px;

                    }
                }

                .user-month {
                    position: relative;
                    text-align: center;
                    width: 100%;
                    margin-bottom: 30px;

                    @media (max-width: $width-576) {
                        margin-bottom: 20px;

                    }

                    &:after {
                        content: "";
                        position: absolute;
                        bottom: -3px;
                        width: 30px;
                        height: 2px;
                        background: #ffc115;
                        transform: translate(-50%, 0px);
                        left: 50%;
                    }
                }

                .course-content {
                    width: 100%;
                    min-height: 100px;

                    @media (max-width: $width-576) {
                        min-height: auto;
                        padding-bottom: 15px;
                    }

                    p {
                        font-size: 15.6px;
                        font-family: 'Acumin Pro Bold';
                        padding-bottom: 5px;

                    }
                }

                .subscribe-signup-btn {
                    background-color: #e1574c;
                    width: 100%;
                    border: 0;
                    padding-top: 3px;

                    @media (max-width: $width-576) {
                        padding-top: 5px;

                    }

                    a {
                        color: white;
                        width: 100%;
                        height: 40px;
                        line-height: 40px;
                        text-align: center;
                        font-size: 16px;
                        text-transform: uppercase;
                        padding: 0;
                        margin: 0;
                        transition: all ease-in-out .3s;
                        display: block;

                        @media (max-width: $width-576) {
                            font-size: 14px;
                            height: 35px;
                            line-height: 35px;
                        }

                        &:hover {
                            color: #ffd200;
                        }
                    }
                }
            }

        }
    }
}